
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { Router } from '@angular/router';
import { Observable, Subject } from 'rxjs';
import { environment } from '../../environments/environment';
import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  private _isLoggedIn = false;
  public providers = ['google', 'twitter', 'facebook'];
  private _authDomain: string = environment.authDomain;
  private _authData$: Subject<any> = new Subject();
  private _refreshToken: string;
  private _accessToken: string;

  get isLoggedIn() {
    return this._isLoggedIn;
  }
  set isLoggedIn(value) {
    this._isLoggedIn = value;
  }

  get authDomain() {
    return this._authDomain;
  }
  set authDomain(value) {
    this._authDomain = value;
  }

  get authData() {
    return this._authData$;
  }
  set authData(value) {
    this._authData$.next(value);
  }

  get refreshToken() {
    return this._refreshToken;
  }
  set refreshToken(value) {
    this._refreshToken = value;
  }

  get accessToken() {
    return this._accessToken;
  }
  set accessToken(value) {
    this._accessToken = value;
  }


  constructor(
    private apiService: ApiService,
    private http: HttpClient,
    private router: Router,
    public angularFireAuth: AngularFireAuth,

  ) {

    this.authData.subscribe((data) => {
      const { accessToken, refreshToken } = data;
      if (accessToken) {
        this.isLoggedIn = true;
        this.refreshToken = refreshToken;
        this.accessToken = accessToken;
      } else {
        this.isLoggedIn = false;

      }
    });
  }


  /**
   * Return oauth related data for ukey
   * @task QLIKTAG-4052
   * @author Neil C 
   * 
   */
  getAuthData$(params: any): Observable<any> {
    return this.apiService.post('oauth', params)
  }

  /**
   * oauth sign in from auth domain to get ukey
   * @param params 
   * @returns 
   */
  diLogin$(params: any): Observable<any> {
    return this.post('oauth/dilogin', params);
  }

  /**
   * Return authDomain url
   * @author Neil C 
   * @task QLIKTAG-4052
   * @param layout 
   * @returns 
   */
  getAuthDomain(layout): string | null {
    let domain = null;
    const { environment, authDomain } = layout;
    // console.log(environment, authDomain);
    if (authDomain) {
      domain = `https://${authDomain}`;
    } else {
      const origin = location.origin;
      //localhost
      if (origin.indexOf('localhost') != -1) {
        //console.log(environment);
        domain = 'localhost';
      } else {
        //other environments
        switch (environment) {
          case 'dev':
            domain = `https://auth.d.qlkt.io`;
            break;
          case 'test':
            domain = `https://auth.t.qlkt.io`;
            break;
          case 'staging':
            domain = `https://auth.s.qlkt.io`;
            break;
          default:
            domain = `http://auth.qlkt.io`;
            break;
        }

      }
    }

    return domain;
  }

  /**
   * Auth Domain POST
   * @param path 
   * @param body 
   * @returns 
   */
  post(path: string, body: Object = {}): Observable<any> {
    return this.http.post(
      `${this.authDomain}/api/${path}`,
      body,
      {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
        })
      });
  }

  /**
   * Set session storage keys
   * @author Neil C 
   * @task QLIKTAG-4052
   * @param data 
   */
  setSession(templateId, instanceId, data) {
    this.authData = data;
    //console.log(data);
    const { userId, accessToken } = data;
    if (userId && accessToken) {
      const sessionKey = `qlkt-${templateId}-${instanceId}`;
      sessionStorage.setItem(sessionKey, JSON.stringify(data));
    }
  }



  async logout(reload: boolean = false) {
    sessionStorage.clear();
    await this.angularFireAuth.signOut();
    if (reload) {
      location.reload()
    }

    return true;
  }
}
